var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-header",
        {
          staticClass: "shadow-border-bottom",
          style: { backgroundColor: _vm.currentTheme.barBackgroundColor },
        },
        [
          [
            _vm.isMobileScreen
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "boreder-right",
                        style: { color: _vm.currentTheme.logoTextColor },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            _vm.isShowLeftMenuDrawer = !_vm.isShowLeftMenuDrawer
                          },
                        },
                      },
                      [
                        _c("em", {
                          staticClass: "iconfont",
                          class: "icon-menu",
                          staticStyle: { "font-size": "26px", padding: "10px" },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "logo-container",
                    style: {
                      color: _vm.currentTheme.logoTextColor,
                      backgroundColor: _vm.currentTheme.logoBackgroundColor,
                      width: _vm.collapse ? "79px" : "285px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "boreder-right",
                        style: { color: _vm.currentTheme.logoTextColor },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            _vm.collapse = !_vm.collapse
                          },
                        },
                      },
                      [
                        _c("em", {
                          staticClass: "iconfont",
                          class: _vm.collapse ? "icon-indent" : "icon-outdent",
                          staticStyle: { "font-size": "30px" },
                        }),
                      ]
                    ),
                    _c("transition", { attrs: { name: "el-fade-in" } }, [
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.collapse,
                            expression: "!collapse",
                          },
                        ],
                        attrs: {
                          src: require("@/assets/images/logo.png"),
                          alt: "",
                        },
                        on: { click: _vm.goHomePage },
                      }),
                    ]),
                  ],
                  1
                ),
          ],
          _c("div", { staticClass: "top-container" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("transition", { attrs: { name: "el-fade-in" } }, [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.collapse,
                        expression: "collapse",
                      },
                    ],
                    attrs: {
                      height: "30px",
                      src: require("@/assets/images/logo.png"),
                      alt: "",
                    },
                    on: { click: _vm.goHomePage },
                  }),
                ]),
                _vm.companyOptions.length
                  ? _c(
                      "div",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c("el-cascader", {
                          staticClass: "switch-company",
                          class: {
                            "switch-company-mobile": _vm.isMobileScreen,
                          },
                          style: { color: _vm.currentTheme.barTextColor },
                          attrs: {
                            size: _vm.isMobileScreen ? "mini" : "default",
                            placeholder: _vm.$t("selectCompanyTeam"),
                            options: _vm.companyOptions,
                          },
                          on: { change: _vm.handleCompanyTeamChange },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ data }) {
                                  return [
                                    _c("em", {
                                      class:
                                        data.type === "team"
                                          ? "el-icon-files"
                                          : "el-icon-suitcase",
                                      staticStyle: { "margin-right": "5px" },
                                    }),
                                    _c("span", [_vm._v(_vm._s(data.label))]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3838652727
                          ),
                          model: {
                            value: _vm.currentCompanyTeam,
                            callback: function ($$v) {
                              _vm.currentCompanyTeam = $$v
                            },
                            expression: "currentCompanyTeam",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "right" }, [
              _c(
                "div",
                [
                  _c("personal-center-popover", {
                    attrs: { username: _vm.user.username || "admin" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "el-container",
        { staticClass: "container-body" },
        [
          !_vm.isMobileScreen
            ? _c(
                "el-aside",
                {
                  class: { "is-collapse": _vm.collapse },
                  style: {
                    backgroundColor: _vm.currentTheme.menuBackgroundColor,
                  },
                  attrs: { width: _vm.collapse ? "79px" : "250px" },
                },
                [
                  !!_vm.defaultActive
                    ? _c("left-menu", {
                        attrs: {
                          leftMenulist: _vm.leftMenulist,
                          currentTheme: _vm.currentTheme,
                          collapse: _vm.collapse,
                        },
                        on: { toCurrentPage: _vm.toCurrentPage },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("el-main", [
            _c("div", { staticClass: "main" }, [
              _c(
                "div",
                { staticClass: "sub-main" },
                [_vm.isRouterAlive ? _c("router-view") : _vm._e()],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "Menu",
            visible: _vm.isShowLeftMenuDrawer,
            direction: "ltr",
            size: "285px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowLeftMenuDrawer = $event
            },
          },
        },
        [
          !!_vm.defaultActive
            ? _c("left-menu", {
                attrs: {
                  leftMenulist: _vm.leftMenulist,
                  currentTheme: _vm.currentTheme,
                  collapse: _vm.collapse,
                },
                on: { toCurrentPage: _vm.toCurrentPage },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }